<template>
  <CFooter>
    <div>
      <a class="footer-links" href="#">{{$t('td-dash')}}</a>
      <span class="ml-1">&copy; 2022.</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1" target="_blank">{{$t('powered_by')}}</span>
      <a class="footer-links" href="https://www.amurait.com">{{$t('amura_it')}}</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
