<template>
  <CSidebar
    aside
    :show.sync="show"
    colorScheme="light"
    overlaid
    :fixed="false"
    size="lg"
    class="sideRight"
  >
    <CSidebarClose @click.native="show = !show" />

    <CTabs tabs class="nav-underline nav-underline-primary">
      <CTab active>
        <template slot="title">
          <CIcon name="cil-list" />
        </template>

        <div class="list-plants">
          <CListGroup class="list-group-accent">
            <CListGroupItem
              class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small"
              >{{ $t("plant_plural") }}</CListGroupItem
            >
            <CListGroupItem
              class="list-group-item-accent-success list-group-item-divider"
              v-for="plant in plants"
              :key="plant.id"
            >
              <div class="float-right">
                <i class="fa fa-th" aria-hidden="true"></i>
              </div>
              {{ plant.name }}{{ plant.id }}
            </CListGroupItem>
          </CListGroup>
        </div>
      </CTab>
    </CTabs>
  </CSidebar>
</template>

<script>
export default {
  name: "TheAside",
  components: {},
  data() {
    return {
      plants: [],
      show: false,
    };
  },
  mounted() {
    this.$root.$on("toggle-aside", () => (this.show = !this.show));
    this.plants = JSON.parse(sessionStorage.getItem("user")).plant;
    // console.log(this.plants);
  },
};
</script>
<style scoped>
.sideRight {
  background-color: white !important;
}

.list-plants {
  max-height: 90vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
</style>