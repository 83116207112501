<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />

    <CHeaderNav>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">Home</CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav class="ml-auto">
      <!-- <TheHeaderDropdownNotif /> -->
    </CHeaderNav>

    <CSubheader class="px-3">
      <div class="mijas">
        <CRow>
          <CCol md="8" lg="8" xs="12">
            <span class="mijasL">
              <CBreadcrumbRouter class="border-0" />
            </span>
          </CCol>
        </CRow>
      </div>

      <div style="clear: both"></div>
    </CSubheader>
  </CHeader>
</template>

<script>
// import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif";
// import Dialog from "primevue/dialog";
// import myclock from "../utils/Clock";
// import Dropdown from "primevue/dropdown";

export default {
  name: "TheHeader",
  components: {
    // TheHeaderDropdownNotif,
    // Dialog,
    // myclock,
    // Dropdown,
  },

  data() {
    return {};
  },

  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.mijas {
  height: 45px;
  /* background-color: white; */
  width: 100%;
}
.mijasR {
  /* background-color: White; */
  padding: 10px;
  padding-right: 10px;
}
.mijasL {
  width: 50%;
  float: left;
}

.planSelector {
  width: 200px;
  margin-left: 10px;
  margin-right: 20px;
}
</style>