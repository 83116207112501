<template>
  <div >
    
    <TheSidebar v-if="showMenuLeft" />
    <TheAside  v-if="showMenuLeft" />
    <div class="c-wrapper">
      <TheHeader/>
      <div class="c-body">
         <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    <theFooter/>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'

import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheAside from './TheAside'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside
  },

  computed: {
    showMenuLeft: function () {
      let online = Vue.prototype.$online;
      let mode = Vue.prototype.$mode;
      return !((mode=='Desktop') && (!online));
    }
  }



}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
